import { Link } from 'gatsby';
import React from 'react';

import * as headerStyles from '../styles/common.module.css';

const HomePageSecondSlider = () =>{
  return(
    <>
      <div className={headerStyles.secondSldMainContainer}>
        <div className="row" style={{ backgroudColor:'#fafcfd',padding: '30px 30px' }}>
          <div className="col-sm-6" style={{ 'float': 'left','padding': '5px','width':'43%' }}>
            <div className={headerStyles.ftContainer}>
              <Link
                to="/">
                {/* <img src={HP_Second_Image} className={headerStyles.Img} ></img> */}
              </Link>
            </div>
          </div>

          <div className={headerStyles.secondSldPraContainer + ' col-sm-4'}>
            <p className={headerStyles.secondSldPraOne}><b>What is Bubble?</b></p>

            <p className={headerStyles.secondSldPraSecond}><b>Life insurance - everybody wants it, few have it.</b></p>

            <h2 className={headerStyles.secondSldPraThird}><b>43% (108M) of adults in the US do not have life insurance. 80% of adults believe they should have it, but don't.</b></h2>

            <div className={headerStyles.secondSldSeperator}></div>

            <h3 className={headerStyles.secondSldPraFourth}> Life insurance is preceived to be a very expensive affairs, whereas in reality it's on average only 1% of total home expenses.</h3>
          </div>
        </div>
      </div>
    </>
  ); };

export default HomePageSecondSlider;
